@import-normalize;



#overlay {
  z-index: 1;
  width: 200px;
  height: 200px;
  background-color: #61dafb;
  position: relative;
}

iframe {
  z-index: 0;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}